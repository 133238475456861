























import { Component, Prop, Vue } from "vue-property-decorator";
import { Route } from "vue-router";
@Component({
  components: {
    MenuNav: () => import("@/components/Menu/MenuNav.vue"),
    CardItem: () => import("@/components/Card/CardItem.vue")
  },
  beforeRouteEnter(to: Route, from: Route, next: (vm: any) => void): void {
    next((vm: any) => {
      vm.$store.commit("CategoriesModule/SELECT", to.params.category);
    });
  }
})
export default class VCategory extends Vue {
  @Prop() readonly category!: number;
  get selected() {
    return this.$store.state.CategoriesModule.selected;
  }
  set selected(v: number) {
    this.$store.commit("CategoriesModule/SELECT", v);
  }
  async created() {
    await this.$store.dispatch("CategoriesModule/updateCategories");
    await this.$store.dispatch("CategoriesModule/updateItems");
  }
}
